<template>
  <div>
    <h1 class="h3 mb-4 text-gray-800">All Organizations</h1>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th>Reg. No.</th>
              <th>Location</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="orgs.length == 0">
              <td colspan="6">There is no data to display.</td>
            </tr>
            <tr v-for="(org, i) in orgs" :key="`org-${i}`">
              <td width="60px">
                <img :src="org.logo" :alt="org.name" style="width: 60px">
              </td>
              <td>{{ org.name }}</td>
              <td>{{ org.reg }}</td>
              <td>{{ org.location }}</td>
              <td>{{ org.status }}</td>
              <td>
                <router-link :to="{ name: 'admin.organizations.show', params: { id: org.id }}">Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      orgs: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/organizations').then(response => {
        this.orgs = response.data.orgs
        this.loading = false
      })
    }
  }
}
</script>