<template>
  <div>
    <div class="card mt-3">
      <div class="card-body" v-if="!loading">
        <div class="cover-image">
          <img :src="org.cover" :alt="org.name" class="org-cover-image w-100" />
        </div>
        <div class="main-info">
          <div class="row">
            <div class="col-md-9">
              <div class="d-flex">
                <div style="max-width: 150px; max-height: 150px" class="shadow">
                  <img :src="org.logo" :alt="org.name" />
                </div>
                <div class="bottomed">
                  <div class="ms-3">
                    <h4>{{ org.name }}</h4>
                    <p>
                      <span class="me-3"
                        ><i class="fa fa-building me-2"></i
                        >{{ org.industry }}</span
                      >
                      <span class="me-3"
                        ><i class="fa fa-map-marker me-2"></i
                        >{{ org.location }}</span
                      >
                      <span class="me-3"
                        ><i class="fa fa-calendar me-2"></i
                        >{{ org.created }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 centered justify-content-end">
              <a href="#" class="btn btn-warning"
                ><i class="fa fa-hourglass me-2"></i>Status: {{ org.status }}</a
              >
              <div class="dropdown no-arrow">
                <button
                  class="btn btn-secondary dropdown-toggle ms-2"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="far fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu p-0">
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'admin.organizations.edit' }"
                      ><i class="fal fa-edit me-2"></i> Edit</router-link
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-danger"
                      href="#"
                      @click.prevent="destroy"
                      ><i class="fal fa-trash me-2"></i> Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <div class="card border mb-3">
                <div class="card-body">
                  <h6>About</h6>
                  <hr />
                  <div v-html="org.about"></div>
                </div>
              </div>
              <div class="card border mb-3">
                <div class="card-body">
                  <router-link
                    :to="{ name: 'admin.organizations.projects.create' }"
                    class="btn btn-xs btn-primary float-end"
                    ><i class="fa fa-plus me-2"></i> Add</router-link
                  >
                  <h6>Projects</h6>
                  <hr />
                  <alert type="warning" v-if="org.projects.length == 0"
                    >There are no active projects.</alert
                  >
                  <table class="table">
                    <tr
                      v-for="(project, i) in org.projects"
                      :key="`project-${i}`"
                    >
                      <td width="60px">
                        <router-link
                          :to="{
                            name: 'admin.my-projects.show',
                            params: { id: project.id },
                          }"
                        >
                          <img
                            :src="project.logo"
                            :alt="project.name"
                            style="height: 50px"
                          />
                        </router-link>
                      </td>
                      <td>
                        <p class="mb-0 py-0 fw-bold">
                          <router-link
                            :to="{
                              name: 'admin.my-projects.show',
                              params: { id: project.id },
                            }"
                            >{{ project.name }}</router-link
                          >
                        </p>
                        <p class="py-0 mb-0">{{ project.description }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="card border mb-3">
                <div class="card-body">
                  <h6>Overall Emitted Vs. Offset Carbon</h6>
                  <hr />
                  <img
                    src="/assets/no-data.png"
                    alt="no data"
                    class="w-100"
                    style="height: 150px"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card border mb-3">
                <div class="card-body">
                  <router-link to="" class="btn btn-xs btn-primary float-end"
                    ><i class="fa fa-plus"></i
                  ></router-link>
                  <h6>Authorized Representatives</h6>
                  <hr />
                  <table class="table table-borderless">
                    <tbody>
                      <tr v-for="(user, i) in org.users" :key="`rep-${i}`">
                        <td>
                          <img
                            :src="user.avatar"
                            :alt="user.name"
                            style="height: 30px"
                          />
                          <span class="ms-2">{{ user.name }}</span>
                        </td>
                        <td>{{ user.role }}</td>
                        <td>
                          <a href="#"><i class="fal fa-info-circle"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card border mb-3">
                <div class="card-body">
                  <h6>Shared Documents</h6>
                  <hr />
                  <alert type="info">No documents</alert>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card border mb-3">
                <div class="card-body">
                  <h6>Retired carbon per sector</h6>
                  <hr />
                  <img
                    src="/assets/no-data.png"
                    alt="no data"
                    class="w-100"
                    style="height: 150px"
                  />
                </div>
              </div>
              <div class="card border mb-3">
                <div class="card-body">
                  <h6><i class="fa fa-th me-2"></i> Activity Timeline</h6>
                  <hr />
                  <ul class="timeline">
                    <li class="timeline-item">
                      <span class="float-end text-xs">Today</span>
                      <p class="fw-bold mb-0">Organization Added</p>
                      <p class="text-muted">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="loading">
        <loading />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      org: {},
      chartData: [
        ["Year", "Emitted", "Offset"],
        ["2014", 1000, 1100],
        ["2015", 1170, 1200],
        ["2016", 660, 820],
        ["2017", 1030, 1300],
      ],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
      },
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/organizations/${this.$route.params.id}`)
        .then((response) => {
          this.org = response.data.org;
          this.loading = false;
        });
    },

    destroy() {
      this.loading = true;

      this.$axios
        .delete(`/api/v1/admin/organizations/${this.$route.params.id}`)
        .then(() => {
          this.$router.push({ name: "admin.organizations" });
        });
    },
  },
};
</script>
